import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"

import { Wrapper, WrapperSizes, MarkedParagraph } from "components"
import { Headline, HeadlineSizes } from "ui"
import S from "./ImageSection.styled"

const ImageSection = ({
  headline,
  paragraphs,
  images,
  reverse,
  children,
  _ref,
}) => (
  <Wrapper size={WrapperSizes.MEDIUM} _ref={_ref}>
    <S.Wrapper reverse={reverse}>
      <S.Column>
        <Headline sizeType={headline.sizeType} tag={headline.tag}>
          {headline.value}
        </Headline>
        <MarkedParagraph paragraphs={paragraphs} />
        {children && <S.AdditionalSection>{children}</S.AdditionalSection>}
      </S.Column>
      <S.ImageColumn>
        {images.map(image => (
          <S.ImageWrapper
            margin={image.margin}
            width={image.width}
            key={Math.random()}
            double={images.length >= 2}
          >
            <S.StyledImage image={getImage(image.fluid)} alt="" />
          </S.ImageWrapper>
        ))}
      </S.ImageColumn>
    </S.Wrapper>
  </Wrapper>
)

ImageSection.propTypes = {
  headline: PropTypes.shape({
    sizeType: PropTypes.oneOf([
      HeadlineSizes.XXL,
      HeadlineSizes.XL,
      HeadlineSizes.L,
      HeadlineSizes.M,
      HeadlineSizes.S,
      HeadlineSizes.XS,
      HeadlineSizes.XXS,
    ]).isRequired,
    tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  paragraphs: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      margin: PropTypes.string,
      width: PropTypes.string,
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
      }),
    })
  ).isRequired,
  reverse: PropTypes.bool,
  children: PropTypes.node,
}

ImageSection.defaultProps = {
  reverse: false,
  children: null,
}

export default ImageSection
