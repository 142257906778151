import React, { useReducer, createContext, useEffect } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled, { ThemeProvider } from "styled-components"
import axios from "axios"
import "normalize.css"

import { colors } from "theme/colors"
import GlobalStyle from "theme/GlobalStyle"
import { Navigation, Cookie } from "components"

export const State = createContext({})

gsap.registerPlugin(ScrollTrigger)

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const InnerWrapper = styled.div``

const initialState = {
  selectedLang: "ES",
  token: "",
  connectionError: false,
  cookies: false,
  initialized: true,
  introAnimationOff: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "CHANGE_LANG":
      localStorage.setItem("selectedLang", action.payload)
      return {
        ...state,
        selectedLang: action.payload,
      }
    case "CHANGE_INTRO_ANIMATION_STATE":
      return {
        ...state,
        introAnimationOff: action.payload,
      }
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      }
    case "SET_CONNECTION_ERROR":
      return {
        ...state,
        connectionError: action.error,
      }
    case "SET_COOKIES":
      return {
        ...state,
        cookiesAccepted: action.cookie,
      }
    default:
      throw new Error()
  }
}

const MainLayout = ({ children, location }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const changeLang = lang => {
    dispatch({ type: "CHANGE_LANG", payload: lang })
  }

  const setIntroAnimation = isOff => {
    dispatch({ type: "CHANGE_INTRO_ANIMATION_STATE", payload: isOff })
  }

  const setCookiesAccepted = cookie => {
    if (cookie) {
      localStorage.setItem("cookiesAccepted", true)
    } else {
      localStorage.removeItem("cookiesAccepted")
    }

    dispatch({ type: "SET_COOKIES", cookie })
  }

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted")

    if (cookiesAccepted) {
      setCookiesAccepted(true)
    } else {
      setCookiesAccepted(false)
    }
  }, [])

  useEffect(() => {
    dispatch({
      type: "CHANGE_LANG",
      payload: location.pathname.includes("/en") ? "EN" : "ES",
    })
  }, [])

  useEffect(() => {
    axios({
      method: "post",
      url: `https://headless.monegroswindfarms.com/wp-json/jwt-auth/v1/token`,
      data: {
        username: "Subscriber",
        password: "Li99TvGo*k4^NnT11(MNVqE^",
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        dispatch({ type: "SET_TOKEN", token: response.data.token })
      })
      .catch(() => dispatch({ type: "SET_CONNECTION_ERROR", token: true }))
  }, [])

  return (
    <State.Provider
      value={{
        location,
        changeLang,
        setIntroAnimation,
        state,
      }}
    >
      <ThemeProvider theme={colors}>
        <GlobalStyle />
        <Wrapper>
          <InnerWrapper>
            <Navigation location={location} />
            {children}
          </InnerWrapper>
        </Wrapper>
        {!state.cookiesAccepted && (
          <Cookie
            handleClick={() => setCookiesAccepted(true)}
            currentLang={state.selectedLang}
          />
        )}
      </ThemeProvider>
    </State.Provider>
  )
}

MainLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default MainLayout
