import styled, { css } from "styled-components"
import { devices } from "theme/devices"
// import { ParagraphSizes } from "./Paragraph"

export const ParagraphSizes = {
  S: "1.4rem",
  M: "1.6rem",
  L: "1.8rem",
  XL: "2.1rem",
}

const Paragraph = styled.p`
  display: block;
  padding: 0;
  margin: 0 0 1em 0;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.75;

  &:last-of-type {
    margin: 0;
  }

  ${({ sizeType }) =>
    sizeType === ParagraphSizes.XL &&
    css`
      font-size: 1.6rem;

      ${devices.mobile} {
        font-size: 1.8rem;
      }

      ${devices.mobileL} {
        font-size: 2.3rem;
      }

      ${devices.tablet} {
        font-size: 2.6rem;
      }

      ${devices.tabletL} {
        font-size: 2.2rem;
      }

      ${devices.desktop} {
        font-size: 2rem;
      }

      ${devices.desktopHD} {
        font-size: 2.8rem;
      }
    `}

  ${({ sizeType }) =>
    sizeType === ParagraphSizes.L &&
    css`
      font-size: 1.6rem;

      ${devices.mobileL} {
        font-size: 1.8rem;
      }

      ${devices.tablet} {
        font-size: 2.1rem;
      }

      ${devices.tabletL} {
        font-size: 1.7rem;
      }

      ${devices.desktop} {
        font-size: 1.8rem;
      }

      ${devices.desktop} {
        font-size: 2rem;
      }
    `}

   ${({ sizeType }) =>
    sizeType === ParagraphSizes.S &&
    css`
      font-size: 1.4rem;

      ${devices.mobile} {
        font-size: 1.5rem;
      }

      ${devices.mobileL} {
        font-size: 1.6rem;
      }

      ${devices.tablet} {
        font-size: 1.8rem;
      }

      ${devices.tabletL} {
        font-size: 1.6rem;
      }
    `}
`

export default { Paragraph }
