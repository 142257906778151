import React from "react"
import PropTypes from "prop-types"

import S from "./Label.styled"

const Label = ({ id, children }) => <S.Label htmlFor={id}>{children}</S.Label>

Label.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default Label
