import styled, { css } from "styled-components"
import { devices } from "theme/devices"

export const HeadlineSizes = {
  XXL: "6.4rem",
  XL: "5.6rem",
  L: "4.5rem",
  M: "3.2rem",
  S: "2.2rem",
  XS: "2rem",
  XXS: "1.8rem",
}

const Headline = styled.h1`
  display: block;
  margin: 0 0 1em 0;
  padding: 0;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;

  ${({ sizeType }) =>
    sizeType === HeadlineSizes.XXL &&
    css`
      font-size: 3.2rem;

      ${devices.mobile} {
        font-size: 4rem;
      }

      ${devices.mobileL} {
        font-size: 4.8rem;
      }

      ${devices.tablet} {
        font-size: 5.4rem;
      }

      ${devices.tabletL} {
        font-size: 4.8rem;
      }

      ${devices.desktop} {
        font-size: 6.6rem;
      }

      ${devices.desktopHD} {
        font-size: 7rem;
      }
    `}

  ${({ sizeType }) =>
    sizeType === HeadlineSizes.XL &&
    css`
      font-size: 2.8rem;
      margin: 0 0 0.6em 0;
      line-height: 1.4;

      ${devices.mobile} {
        font-size: 3rem;
      }

      ${devices.mobileL} {
        font-size: 3.6rem;
      }

      ${devices.tablet} {
        font-size: 3.8rem;
      }

      ${devices.desktop} {
        font-size: 4.4rem;
      }
    `}

   ${({ sizeType }) =>
    sizeType === HeadlineSizes.L &&
    css`
      font-size: 2.2rem;
      margin: 0 0 0.7em 0;

      ${devices.mobile} {
        font-size: 2.4rem;
      }

      ${devices.mobileL} {
        font-size: 3rem;
      }

      ${devices.desktop} {
        font-size: 4rem;
      }

      ${devices.desktopHD} {
        font-size: 4.2rem;
      }
    `}

   ${({ sizeType }) =>
    sizeType === HeadlineSizes.M &&
    css`
      font-size: 2rem;
      margin: 0 0 0.7em 0;

      ${devices.mobile} {
        font-size: 2.2rem;
        margin: 0 0 0.6em 0;
      }

      ${devices.mobileL} {
        font-size: 2.6rem;
      }

      ${devices.desktop} {
        font-size: 2.8rem;
      }

      ${devices.desktopHD} {
        font-size: 2.4rem;
      }
    `}

   ${({ sizeType }) =>
    sizeType === HeadlineSizes.S &&
    css`
      font-size: 2rem;
      margin: 0 0 0.7em 0;
    `}

   ${({ sizeType }) =>
    sizeType === HeadlineSizes.XS &&
    css`
      font-size: 1.8rem;
      margin: 0 0 0.7em 0;

      ${devices.mobile} {
        font-size: 1.9rem;
      }

      ${devices.mobileL} {
        font-size: 2rem;
      }

      ${devices.tablet} {
        font-size: 2.2rem;
      }

      ${devices.tabletL} {
        font-size: 2rem;
      }

      ${devices.desktopHD} {
        font-size: 2.2rem;
      }
    `}
`

export default { Headline }
