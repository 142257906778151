import { Link } from "gatsby"
import styled from "styled-components"

import { devices } from "theme/devices"
import Wrapper from "components/Wrapper"

const Bar = styled.div`
  background-color: ${({ theme }) => theme.primary20};
  font-size: 1.2rem;
  margin: 0 0 2rem -1em;
  min-height: 5rem;

  ${devices.mobileL} {
    padding: 0.2rem 0;
    font-size: 1.4rem;
  }

  ${devices.tablet} {
    font-size: 1.6rem;
  }

  ${devices.desktop} {
    padding: 0.2rem 0 0.1rem;
    font-size: 1.4rem;
  }
`

const StyledWrapper = styled(Wrapper)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;

  ${devices.mobileL} {
    padding: 0 3rem;
  }

  ${devices.desktop} {
    padding: 0 4rem;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CrambLink = styled(Link)`
  position: relative;
  display: block;
  padding: 1em 1em 1.2em;
  color: ${({ theme }) => theme.primary40};
  text-decoration: none;
  font-size: inherit;
  font-weight: 500;

  &.active {
    color: ${({ theme }) => theme.primary60};
  }

  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 0.1rem;
    height: 30%;
    background-color: ${({ theme }) => theme.primary40};
  }
`

export default { Bar, CrambLink, InnerWrapper, StyledWrapper }
