import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import S from "./Logo.styled"

const query = graphql`
  {
    allFile(filter: { name: { regex: "/monegros-logo/" } }) {
      nodes {
        publicURL
      }
    }
  }
`

const Logo = ({ white }) => {
  const {
    allFile: { nodes: logos },
  } = useStaticQuery(query)

  return (
    <S.NavLink to="/">
      <S.Logo
        white={white}
        src={
          white
            ? logos?.filter(logo => logo.publicURL.includes("white"))[0]
                .publicURL
            : logos?.filter(logo => !logo.publicURL.includes("white"))[0]
                .publicURL
        }
      />
    </S.NavLink>
  )
}

Logo.propTypes = {
  white: PropTypes.bool,
}

Logo.defaultProps = {
  white: false,
}

export default Logo
