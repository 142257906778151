import styled from "styled-components"
import { devices } from "theme/devices"

const Wrapper = styled.div`
  ${devices.mobile} {
    display: flex;
    align-items: center;
    margin: 2rem 0;
  }
`

const Icon = styled.img`
  display: block;
  height: 2.2rem;
  margin: 0 auto;

  ${devices.mobile} {
    margin: 0;
    height: 2.5rem;
  }

  ${devices.tablet} {
    height: 3rem;
  }

  ${devices.tabletL} {
    height: 2.2rem;
  }
`

const Text = styled.span`
  display: block;
  margin: 1rem 0 2.5rem 0;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;

  ${devices.mobile} {
    margin: 0 0 0 1.5rem;
    text-align: left;
    font-size: 1.5rem;
  }

  ${devices.mobileL} {
    margin-right: 2rem;
    font-size: 1.6rem;
  }

  ${devices.tablet} {
    font-size: 1.8rem;
  }

  ${devices.tabletL} {
    font-size: 1.4rem;
  }
`

export default { Wrapper, Icon, Text }
