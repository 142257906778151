import styled from "styled-components"
import { devices } from "theme/devices"
import { Headline } from "ui"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto 2rem;

  &:last-of-type {
    margin: 0 auto;
  }

  ${devices.mobile} {
    width: 60%;
  }

  ${devices.mobileL} {
    width: 50%;
    padding: 2rem;

    &:last-of-type {
      margin: 0 auto 2rem;
    }
  }

  ${devices.tabletL} {
    width: 33.3333%;
    margin: 0 auto 2rem;
  }

  ${devices.desktopHD} {
    align-items: flex-start;

    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ & {
      width: 33.3333%;
    }

    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ & {
      width: 25%;
    }

    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ & {
      width: 20%;
    }

    margin: 0 auto;
    padding: 3rem;

    &:last-of-type {
      margin: 0 auto;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(calc(-50% - 1rem));
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary40};

    ${devices.mobileL} {
      width: 6rem;
      height: 6rem;
      top: 3rem;
    }

    ${devices.desktopHD} {
      width: 7rem;
      height: 7rem;
      top: 5rem;
      left: 3rem;
      transform: translateX(0);
    }
  }
`

const Icon = styled.img`
  position: relative;
  display: block;
  margin: 0 0 2rem 2rem;
  width: 6rem;

  ${devices.mobileL} {
    width: 7rem;
  }

  ${devices.desktopHD} {
    width: 8rem;
    margin: 0 0 2rem 3rem;
  }
`

const ParagraphWrapper = styled.div`
  padding: 0;
  margin: 0.3rem auto 1em auto;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

  ${devices.mobile} {
    margin-top: 0.6rem;
    font-size: 1.6rem;
  }

  ${devices.mobileL} {
    margin-top: 1rem;
    font-size: 1.8rem;
  }

  ${devices.tablet} {
    font-size: 2rem;
  }

  ${devices.tabletL} {
    font-size: 1.6rem;
  }

  ${devices.desktopHD} {
    margin-bottom: 0;
    font-size: 1.8rem;
    text-align: left;
  }
`

const StyledHeadline = styled(Headline)`
  margin-top: 1rem;
  text-align: center;

  ${devices.mobile} {
    margin-top: 2rem;
  }

  ${devices.desktopHD} {
    text-align: left;
  }
`

export default { Wrapper, Icon, StyledHeadline, ParagraphWrapper }
