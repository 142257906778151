import React from "react"
import PropTypes from "prop-types"

import { HeadlineSizes } from "ui"
import S from "./Header.styled"

const Header = ({ title, children, id }) => (
  <S.Header notFullWidth={children === null} id={id}>
    <S.StyledHeadline sizeType={HeadlineSizes.XL} tag="h1">
      {title}
    </S.StyledHeadline>
    {children}
  </S.Header>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  id: PropTypes.string,
}

Header.defaultProps = {
  children: null,
  id: "",
}

export default Header
