import styled, { css } from "styled-components"
import { devices } from "theme/devices"
import Wrapper from "../Wrapper/Wrapper"

const NavigationWrapper = styled.nav`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${({ theme }) => theme.primary20};
  opacity: ${({ subpage }) => (subpage ? "1" : "0")};
`

const NavigationContent = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  ${devices.mobile} {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }

  ${devices.mobileL} {
    padding: 1.8rem 3rem;
  }

  ${devices.tablet} {
    padding: 2.2rem 4rem;
  }

  ${devices.tabletL} {
    padding: 1.8rem 4rem;
  }

  ${devices.desktopHD} {
    padding: 2rem 0;
  }
`

const NavigationList = styled.ul`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding: 4rem 0;
  margin: 0;
  background: ${({ theme }) => theme.neutral20};
  transition: transform 0.25s ease-in;
  transform: translateX(-100%);

  @media (min-height: 420px) {
    justify-content: center;
  }

  ${({ visible }) =>
    visible &&
    css`
      transform: translateX(0);
    `}

  ${devices.tabletL} {
    position: relative;
    top: auto;
    left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    width: auto;
    height: initial;
    padding: 0;
    margin: 0;
    transform: translateX(0);
    background: none;
  }
`

export default { NavigationWrapper, NavigationContent, NavigationList }
