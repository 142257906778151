import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"

import { Headline, HeadlineSizes, PhraseLabel, ParagraphSizes } from "ui"
import S from "./News.styled"

const News = ({ date, title, lead, imageFluid }) => (
  <S.Box>
    <S.NewsData>
      <PhraseLabel>{date}</PhraseLabel>
      <Headline sizeType={HeadlineSizes.XS} tag="h2">
        {title}
      </Headline>
      <S.StyledParagraph as="div" sizeType={ParagraphSizes.S}>
        {lead}
      </S.StyledParagraph>
    </S.NewsData>
    <S.ImageWrapper>
      <S.StyledImage image={getImage(imageFluid)} alt='image' />
    </S.ImageWrapper>
  </S.Box>
)

News.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default News
