import styled, { css } from "styled-components"
import { devices } from "theme/devices"
import { Headline } from "ui"

const Header = styled.header`
  display: block;
  margin: 0;

  ${devices.mobile} {
    margin-bottom: 3rem;
  }

  ${devices.tabletL} {
    margin-bottom: 0;
  }

  ${({ notFullWidth }) =>
    notFullWidth &&
    css`
      ${devices.mobile} {
        width: 80%;
      }
    `}
`

const StyledHeadline = styled(Headline)`
  ${devices.tabletL} {
    width: 50%;
  }
`

export default { Header, StyledHeadline }
