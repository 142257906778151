import React from "react"
import PropTypes from "prop-types"
import S from "./NavigationItem.styled"

const NavigationItem = ({ link, children }) => (
  <S.NavigationItem>
    <S.StyledLink to={link} activeClassName="active">
      {children}
    </S.StyledLink>
  </S.NavigationItem>
)

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default NavigationItem
