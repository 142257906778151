import React from "react"
import PropTypes from "prop-types"

import { IconColumn } from "components"
import { WrapperSizes } from "components/Wrapper"
import S from "./IconsSection.styled"

const IconsSection = ({ icons, width, wrapperSize, _ref }) => (
  <S.FullBackground ref={_ref}>
    <S.InnerWrapper size={wrapperSize} pushWithPadding>
      {icons?.map(icon => (
        <IconColumn
          key={Math.random()}
          iconUrl={icon.url}
          headline={icon.headline || ""}
          paragraph={icon.paragraph}
          width={width}
        />
      ))}
    </S.InnerWrapper>
  </S.FullBackground>
)

IconsSection.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      paragraph: PropTypes.node.isRequired,
      headline: PropTypes.string,
      width: PropTypes.string,
    }).isRequired
  ).isRequired,
  width: PropTypes.string.isRequired,
  wrapperSize: PropTypes.oneOf([
    WrapperSizes.SMALL,
    WrapperSizes.MEDIUM,
    WrapperSizes.LARGE,
  ]),
}

IconsSection.defaultProps = {
  wrapperSize: WrapperSizes.SMALL,
}

export default IconsSection
