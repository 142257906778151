import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    html {
        overflow-y: scroll;
        font-family: Montserrat, Arial, Helvetica, sans-serif;
        font-size: 10px;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 5px;
            background: ${({ theme }) => theme.primary20};
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: ${({ theme }) => theme.primary40};
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${({ theme }) => theme.primary60};
        }
    }

    body {
        width: 100%;
        font-size: 1.6rem;
    }

    p {
        margin: 0 0 1em 0;
    }
`

export default GlobalStyle
