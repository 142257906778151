exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-js": () => import("./../../../src/pages/acerca-de.js" /* webpackChunkName: "component---src-pages-acerca-de-js" */),
  "component---src-pages-beneficios-js": () => import("./../../../src/pages/beneficios.js" /* webpackChunkName: "component---src-pages-beneficios-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-benefits-js": () => import("./../../../src/pages/en/benefits.js" /* webpackChunkName: "component---src-pages-en-benefits-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-project-js": () => import("./../../../src/pages/en/project.js" /* webpackChunkName: "component---src-pages-en-project-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-proyecto-js": () => import("./../../../src/pages/proyecto.js" /* webpackChunkName: "component---src-pages-proyecto-js" */)
}

