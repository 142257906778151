import styled, { css } from "styled-components"
import { devices } from "theme/devices"

const Button = styled.button`
  position: relative;
  z-index: 2;
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 0 -0.4rem 0 0;
  border: none;
  background: none;
  transform: rotate(0);
  transition: transform 0.25s ease-in;

  &:focus {
    outline: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 2.2rem;
    height: 0.2rem;
    background: ${({ theme }) => theme.neutral60};
    transition: transform 0.25s ease-in;

    ${devices.mobileL} {
      width: 3rem;
    }
  }

  &::before {
    transform: translate(-50%, calc(-50% - 0.2rem)) rotate(0);

    ${devices.mobileL} {
      transform: translate(-50%, calc(-50% - 0.3rem)) rotate(0);
    }
  }

  &::after {
    transform: translate(-50%, calc(-50% + 0.2rem)) rotate(0);

    ${devices.mobileL} {
      transform: translate(-50%, calc(-50% + 0.3rem)) rotate(0);
    }
  }

  ${({ active }) =>
    active &&
    css`
      transform: rotate(90deg);

      &::before {
        transform: translate(-50%, calc(-50%)) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, calc(-50%)) rotate(-45deg);
      }
    `}
`

export default { Button }
