import styled, { css } from "styled-components"
import { devices } from "theme/devices"

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 1.4rem 1.5rem;
  border: 0.1rem solid ${({ theme }) => theme.primary40};
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.neutral20};
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.5rem;
  font-weight: 400;

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.secondary20};
  }

  &::placeholder {
    color: ${({ theme }) => theme.neutral40};
    font-weight: 400;
  }

  ${({ typeTextarea }) =>
    typeTextarea &&
    css`
      height: 8em;
    `}

  ${devices.mobileL} {
    font-size: 1.6rem;
    padding: 1.9rem 1.5rem 1.8rem;
  }
`

export default { Input }
