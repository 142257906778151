import React, { useContext } from "react"
import PropTypes from "prop-types"
import { State } from "layouts"
import S from "./Language.styled"

const switchToEnglish = path => {
  switch (path) {
    case "/en":
    default:
      return "/"
    case "/en/about-us":
      return "/acerca-de"
    case "/en/project":
      return "/proyecto"
    case "/en/benefits":
      return "/beneficios"
    case "/en/news":
      return "/noticias"
    case "/en/contact":
      return "/contacto"
    case "/en/privacy-policy":
      return "/politica-de-privacidad"
  }
}

const switchToSpanish = path => {
  switch (path) {
    case "/":
    default:
      return "/en"
    case "/acerca-de":
      return "/en/about-us"
    case "/proyecto":
      return "/en/project"
    case "/beneficios":
      return "/en/benefits"
    case "/noticias":
      return "/en/news"
    case "/contacto":
      return "/en/contact"
    case "/politica-de-privacidad":
      return "/en/privacy-policy"
  }
}

const Language = ({ mobile }) => {
  const { changeLang, state, location } = useContext(State)

  return mobile ? (
    <S.WrapperMobile>
      <S.LanguageItem
        to={switchToEnglish(location.pathname)}
        activeClassName="active"
        partiallyActive={state.selectedLang === "ES"}
        onClick={() => {
          changeLang("ES")
        }}
      >
        ES
      </S.LanguageItem>
      <S.LanguageItem
        to={switchToSpanish(location.pathname)}
        activeClassName="active"
        partiallyActive={state.selectedLang === "EN"}
        onClick={() => {
          changeLang("EN")
        }}
      >
        EN
      </S.LanguageItem>
    </S.WrapperMobile>
  ) : (
    <S.Wrapper>
      <S.LanguageItem
        to={switchToEnglish(location.pathname)}
        activeClassName="active"
        partiallyActive={state.selectedLang === "ES"}
        onClick={() => {
          changeLang("ES")
        }}
      >
        ES
      </S.LanguageItem>
      <S.LanguageItem
        to={switchToSpanish(location.pathname)}
        activeClassName="active"
        partiallyActive={state.selectedLang === "EN"}
        onClick={() => {
          changeLang("EN")
        }}
      >
        EN
      </S.LanguageItem>
    </S.Wrapper>
  )
}

Language.propTypes = {
  mobile: PropTypes.bool,
}

Language.defaultProps = {
  mobile: false,
}

export default Language
