import React from "react"
import PropTypes from "prop-types"

import S, { ParagraphSizes } from "./Paragraph.styled"

const Paragraph = ({ children, sizeType, className, as }) => (
  <S.Paragraph
    sizeType={sizeType || ParagraphSizes.M}
    className={className}
    as={as}
  >
    {children}
  </S.Paragraph>
)

Paragraph.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  sizeType: PropTypes.oneOf([
    ParagraphSizes.XL,
    ParagraphSizes.L,
    ParagraphSizes.M,
    ParagraphSizes.S,
  ]).isRequired,
  className: PropTypes.string,
  as: PropTypes.string,
}

Paragraph.defaultProps = {
  className: "",
  as: "",
}

export default Paragraph
