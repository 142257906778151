import styled from "styled-components"
import { devices } from "theme/devices"
import Wrapper from "../Wrapper/Wrapper"

const FullBackground = styled.div`
  padding: 2rem 0;
  margin: 4rem 0;
  background: ${({ theme }) => theme.primary20};

  ${devices.mobileL} {
    display: flex;
  }

  ${devices.mobileL} {
    margin: 8rem 0;
  }
`

const InnerWrapper = styled(Wrapper)`
  ${devices.mobileL} {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }

  ${devices.desktopHD} {
    padding: 4rem 0;
  }
`

export default { FullBackground, InnerWrapper }
