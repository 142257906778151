import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import S from "./IconItem.styled"

const query = graphql`
  {
    allFile(filter: { name: { regex: "/email|phone/" } }) {
      nodes {
        publicURL
      }
    }
  }
`

const IconItem = ({ phone, children }) => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query)

  return (
    <S.Wrapper>
      <S.Icon
        src={
          phone
            ? nodes.filter(icon => icon.publicURL.includes("phone"))[0]
                .publicURL
            : nodes.filter(icon => icon.publicURL.includes("email"))[0]
                .publicURL
        }
      />
      <S.Text>{children}</S.Text>
    </S.Wrapper>
  )
}

IconItem.propTypes = {
  phone: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

IconItem.defaultProps = {
  phone: false,
}

export default IconItem
