import { Link } from "gatsby"
import styled from "styled-components"

import { devices } from "theme/devices"

const Footer = styled.footer`
  display: block;
  margin-top: 2rem;
  background: ${({ theme }) => theme.neutral60};
`

const InnerWrapper = styled.div`
  ${devices.tabletL} {
    display: flex;
  }
`

const FooterColumn = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${devices.mobileL} {
    padding: 3rem 2rem;
  }

  ${devices.tabletL} {
    width: 25%;
    align-items: flex-start;
  }
`

const FooterHeadline = styled.h2`
  padding: 0;
  margin: 0 0 0.75rem 0;
  color: ${({ theme }) => theme.primary20};
  font-size: 1.5rem;
  font-weight: 600;

  ${devices.mobileL} {
    font-size: 1.8rem;
  }

  ${devices.tabletL} {
    font-size: 1.6rem;
    margin-bottom: 1em;
  }

  ${devices.desktopHD} {
    font-size: 1.8rem;
  }
`

const InnerSection = styled.div`
  margin-top: 5rem;
`

const NavigationList = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
`

const NavigationItem = styled.li`
  display: block;
  padding: 0;
  margin: 0 auto;
  text-align: center;

  ${devices.tabletL} {
    text-align: left;
  }
`

const NavigationLink = styled(Link)`
  display: block;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  color: ${({ theme }) => theme.primary40};
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.neutral40};
  }

  &:hover {
    text-decoration: underline;
  }

  ${devices.mobile} {
    font-size: 1.6rem;
  }

  ${devices.mobileL} {
    font-size: 1.8rem;
  }

  ${devices.tabletL} {
    font-size: 1.4rem;
  }
`

const ContactDetails = styled.div`
  > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    ${devices.tabletL} {
      display: block;
    }

    > li {
      display: block;
      padding: 0.5rem 0 0;
      margin: 0.5rem 0 0;
      color: ${({ theme }) => theme.primary40};
      font-size: 1.5rem;
      font-weight: 400;

      ${devices.mobileL} {
        font-size: 1.8rem;
      }

      ${devices.tabletL} {
        font-size: 1.4rem;
      }
    }
  }
`

const PartnerLogo = styled.img`
  display: block;
  width: 12rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  
  &:last-of-type {
    margin-bottom: 0;
  }

  ${devices.mobileL} {
    width: 16rem;
  }

  ${devices.tabletL} {
    width: 13rem;
    margin-top: 0;
  }
`

export default {
  Footer,
  InnerWrapper,
  PartnerLogo,
  ContactDetails,
  FooterColumn,
  FooterHeadline,
  NavigationList,
  NavigationItem,
  NavigationLink,
  InnerSection,
}
