import styled from "styled-components"
import { devices } from "theme/devices"

const Label = styled.label`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 0.6em 0.5rem;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;

  ${devices.mobileL} {
    font-size: 1.3rem;
  }
`

export default { Label }
