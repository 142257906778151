import React, { useState, useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { State } from "layouts"
import S from "./Cookie.styled"

const query = graphql`
  {
    allWpPage(filter: { privacy: { privacyLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          privacy {
            privacyLang
            cookiesBar {
              cookiesAccept
              cookiesMessage
            }
          }
        }
      }
    }
  }
`

const Cookie = ({ handleClick }) => {
  const {
    allWpPage: { edges },
  } = useStaticQuery(query)

  const { state } = useContext(State)
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    if (!state.selectedLang) return

    const {
      node: { privacy },
    } = edges.find(
      ({ node }) => node?.privacy?.privacyLang === state.selectedLang
    )

    setPageData(privacy)
  }, [state])

  if (!pageData) return null

  return (
    <S.Wrapper>
      <S.Button onClick={handleClick}>
        {pageData?.cookiesBar?.cookiesAccept}
      </S.Button>
      <S.Information>
        <S.Data>{pageData?.cookiesBar?.cookiesMessage}</S.Data>
        <S.StyledLink
          to={
            state.selectedLang === "EN"
              ? "/en/privacy-policy"
              : "/politica-de-privacidad"
          }
        >
          {state.selectedLang === "EN"
            ? "Privacy Policy"
            : "Política de privacidad"}
        </S.StyledLink>
      </S.Information>
    </S.Wrapper>
  )
}

Cookie.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default Cookie
