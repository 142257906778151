import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import S from "./PeakWindLogo.styled"

const query = graphql`
  {
    file(name: { regex: "/peakwind/" }) {
      publicURL
    }
  }
`

const PeakWindLogo = () => {
  const {
    file: { publicURL },
  } = useStaticQuery(query)

  return <S.PEAKLogo src={publicURL} />
}

export default PeakWindLogo
