export const colors = {
  primary20: "#E4F2FB",
  primary40: "#A3BDDB",
  primary60: "#1D7EAF",
  secondary20: "#F37521",
  secondary40: "#AB4825",
  neutral20: "#ffffff",
  neutral40: "#858DA9",
  neutral60: "#074A71",
}

export default colors
