import styled from "styled-components"
import { devices } from "theme/devices"

const PEAKLogo = styled.img`
  display: block;
  width: 12rem;
  margin-top: 2rem;

  ${devices.mobileL} {
    width: 16rem;
  }

  ${devices.tabletL} {
    width: 13rem;
  }
`

export default { PEAKLogo }
