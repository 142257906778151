import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { devices } from "theme/devices"

const Image = styled(GatsbyImage)`
  display: block;
  height: 20rem;
  margin: 2rem 0;
  object-fit: cover;

  &:last-of-type {
    margin: 2rem 0 0;
  }

  ${devices.mobile} {
    width: calc(50% - 2rem);
    margin: 1rem;

    &:last-of-type {
      margin: 1rem;
    }
  }

  ${devices.desktop} {
    width: calc(33.3333% - 2rem);
    margin: 1rem;

    &:last-of-type {
      margin: 1rem;
    }
  }

  ${devices.desktopHD} {
    width: calc(25% - 2rem);
  }
`

export default { Image }
