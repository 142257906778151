import styled from "styled-components"
import { devices } from "theme/devices"
import { Headline } from "ui"
import Wrapper from "../Wrapper/Wrapper"

const Gallery = styled.div`
  ${devices.mobile} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem 0 -1rem;
  }
`

const StyledWrapper = styled(Wrapper)`
  ${devices.desktop} {
    margin: 8rem auto;
  }
`

const StyledHeadline = styled(Headline)`
  text-align: center;
`

export default { Gallery, StyledWrapper, StyledHeadline }
