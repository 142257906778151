import styled, { css } from "styled-components"
import { devices } from "theme/devices"

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  margin: 4rem auto;
  padding: 0 2rem;

  ${devices.mobile} {
    padding: 0 3rem;
    margin: 5rem auto;
  }

  ${devices.mobileL} {
    padding: 0 6rem;
    margin: 8rem auto;
  }

  ${devices.tablet} {
    padding: 0 8rem;
    margin: 10rem auto;
  }

  ${devices.tabletL} {
    padding: 0 6rem;
    margin: 8rem auto;
  }

  ${devices.desktop} {
    padding: 0 4rem;
    margin: 4rem auto;
  }

  ${devices.desktopHD} {
    padding: 0;
    margin: 10rem auto;
  }

  ${({ pushWithPadding }) =>
    pushWithPadding &&
    css`
      padding: 2rem;
      margin: 0 auto;

      ${devices.mobile} {
        padding: 3rem;
        margin: 0 auto;
      }

      ${devices.mobile} {
        padding: 6rem;
        margin: 0 auto;
      }

      ${devices.tablet} {
        padding: 8rem;
        margin: 0 auto;
      }

      ${devices.tabletL} {
        padding: 6rem;
        margin: 0 auto;
      }

      ${devices.desktop} {
        padding: 4rem;
        margin: 0 auto;
      }

      ${devices.desktopHD} {
        padding: 10rem 0;
        margin: 0 auto;
      }
    `}
`

export default { Wrapper }
