import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import S from "./Download.styled"

const query = graphql`
  {
    file(name: { eq: "download" }) {
      publicURL
    }
  }
`

const Download = ({ links }) => {
  const { file } = useStaticQuery(query)

  return (
    <S.Wrapper>
      <S.Icon src={file.publicURL} alt='icon' />
      <S.LinksWrapper>
        {links.map(
          link =>
            !!link?.url && (
              <S.Link
                key={`${Math.random() + link.name}`}
                href={link.url}
                target="_blank"
              >
                {link.name}
              </S.Link>
            )
        )}
      </S.LinksWrapper>
    </S.Wrapper>
  )
}

Download.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
}

export default Download
