import React from "react"
import PropTypes from "prop-types"

import S from "./PhraseLabel.styled"

const PhraseLabel = ({ children, uppercase }) => (
  <S.PhraseLabel uppercase={uppercase}>{children}</S.PhraseLabel>
)

PhraseLabel.propTypes = {
  children: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
}

PhraseLabel.defaultProps = {
  uppercase: false,
}

export default PhraseLabel
