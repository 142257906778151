import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { devices } from "theme/devices"

const Logo = styled.img`
  position: relative;
  z-index: 2;
  display: block;
  width: 10rem;

  ${devices.mobile} {
    width: 12rem;
  }

  ${devices.mobileL} {
    width: 14rem;
  }

  ${devices.tablet} {
    width: 17rem;
  }

  ${devices.tabletL} {
    width: 14rem;
  }

  ${devices.desktopHD} {
    width: 16rem;
  }

  ${({ white }) =>
    white &&
    css`
      margin-top: 2rem;
      width: 9rem;

      ${devices.mobile} {
        width: 12rem;
      }

      ${devices.mobileL} {
        width: 14rem;
        margin-top: 2rem;
      }

      ${devices.tablet} {
        width: 13rem;
        margin-top: 3rem;
      }

      ${devices.tabletL} {
        width: 10rem;
        margin-top: 6rem;
      } ;
    `};
`

const NavLink = styled(Link)`
  display: block;
  text-decoration: none;
`

export default { Logo, NavLink }
