import styled from "styled-components"
import { devices } from "theme/devices"

const Marker = styled.span`
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0.45rem 3.6rem 0 0;
  background-color: ${({ theme }) => theme.primary20};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    display: block;
    width: 2.5rem;
    height: 0.2rem;
    background-color: ${({ theme }) => theme.secondary20};
  }

  ${devices.tabletL} {
    width: 2rem;
    height: 2rem;
    margin: 0.35rem 6rem 0 0;

    &::after {
      width: 4rem;
    }
  }
`

export default { Marker }
