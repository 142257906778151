import styled from "styled-components"
import { Link } from "gatsby"
import { devices } from "theme/devices"

const NavigationItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
`

const StyledLink = styled(Link)`
  display: block;
  padding: 1rem 1.25rem;
  margin: 0.2rem 0;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: lowercase;

  &.active {
    color: ${({ theme }) => theme.secondary20};
  }

  ${devices.mobile} {
    font-size: 1.6rem;
    margin: 0.4rem;
  }

  ${devices.mobileL} {
    font-size: 1.8rem;
    margin: 0.5rem 0.8rem;
  }

  ${devices.tablet} {
    font-size: 2rem;
    margin: 0.8rem;
  }

  ${devices.tabletL} {
    font-size: 1.5rem;
    margin: 0 0.4rem;
  }

  ${devices.desktop} {
    font-size: 1.6rem;
    margin: 0 1rem;
  }

  ${devices.desktopHD} {
    margin: 0 2rem;
  }
`

export default { NavigationItem, StyledLink }
