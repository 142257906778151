import styled from "styled-components"
import { devices } from "theme/devices"

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const Icon = styled.img`
  display: block;
  width: 4rem;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.2rem 0 0 1.2rem;
`

const Link = styled.a`
  position: relative;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 3.4rem;
  color: ${({ theme }) => theme.neutral60};
  margin-right: 4rem;
  text-decoration: none;

  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2rem;
    transform: translateY(calc(-50% - 0.2rem));
    background: ${({ theme }) => theme.neutral60};
    width: 0.2rem;
    height: 2rem;
  }

  &:hover {
    text-decoration: underline;
  }

  ${devices.tablet} {
    font-size: 1.6rem;
  }
`

export default { Wrapper, Icon, LinksWrapper, Link }
