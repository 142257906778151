import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"

import { Logo } from "ui"
import { State } from "layouts"
import { WrapperSizes } from "components"
import { gsap } from "gsap"

import Burger from "./Burger"
import Language from "./Language"
import NavigationItem from "./NavigationItem"
import S from "./Navigation.styled"

const Navigation = ({ location }) => {
  const [visible, setVisible] = useState(false)
  const [mobile, setMobile] = useState(true)

  const { state } = useContext(State)

  useEffect(() => {
    if (visible) {
      document.body.style.position = "fixed"
      document.body.style.top = "0"
      document.body.style.left = "0"
      window.scrollTo(0, 0)
    } else {
      document.body.removeAttribute("style")
    }
  }, [visible])

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      document.body.removeAttribute("style")
      setMobile(false)
      return
    }

    setMobile(true)
    setVisible(false)
    document.body.removeAttribute("style")
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    setVisible(false)
    document.body.removeAttribute("style")
  }, [location])

  const navigationRef = useRef(null)

  useEffect(() => {
    if (location.pathname !== "/" || state.introAnimationOff) return

    const tl = gsap.timeline()

    tl.fromTo(
      navigationRef.current,
      { opacity: 0, autoAlpha: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        autoAlpha: 1,
        delay: 1.8,
      }
    )
  }, [navigationRef, location])

  return (
    <S.NavigationWrapper
      ref={navigationRef}
      subpage={location.pathname !== "/" || state.introAnimationOff}
    >
      <S.NavigationContent size={WrapperSizes.LARGE} pushWithPadding>
        <Logo />
        {mobile && (
          <Burger
            handleClick={() => setVisible(prevVisible => !prevVisible)}
            active={visible}
          />
        )}
        <S.NavigationList visible={visible}>
          <NavigationItem link={state.selectedLang === "ES" ? "/" : "/en"}>
            {state.selectedLang === "ES" ? "Principal" : "Home"}
          </NavigationItem>
          <NavigationItem
            link={state.selectedLang === "ES" ? "/acerca-de" : "/en/about-us"}
          >
            {state.selectedLang === "ES" ? "Acerca de" : "About us"}
          </NavigationItem>
          <NavigationItem
            link={state.selectedLang === "ES" ? "/proyecto" : "/en/project"}
          >
            {state.selectedLang === "ES" ? "Proyecto" : "The Project"}
          </NavigationItem>
          <NavigationItem
            link={state.selectedLang === "ES" ? "/beneficios" : "/en/benefits"}
          >
            {state.selectedLang === "ES" ? "Beneficios" : "Benefits"}
          </NavigationItem>
          <NavigationItem
            link={state.selectedLang === "ES" ? "/noticias" : "/en/news"}
          >
            {state.selectedLang === "ES" ? "Noticias" : "News"}
          </NavigationItem>
          <NavigationItem
            link={state.selectedLang === "ES" ? "/contacto" : "/en/contact"}
          >
            {state.selectedLang === "ES" ? "Contacto" : "Contact us"}
          </NavigationItem>
          {mobile && <Language mobile={mobile} />}
        </S.NavigationList>
        {!mobile && <Language location={location} />}
      </S.NavigationContent>
    </S.NavigationWrapper>
  )
}

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Navigation
