import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"

import S from "./GalleryItem.styled"

const GalleryItem = ({ imageUrl }) => (
  <>{imageUrl && <S.Image image={getImage(imageUrl)} alt="" />}</>
)

GalleryItem.propTypes = {
  imageUrl: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
}

GalleryItem.defaultProps = {
  imageUrl: null,
}

export default GalleryItem
