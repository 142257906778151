import React from "react"
import PropTypes from "prop-types"

import { HeadlineSizes } from "ui"
import S from "./IconColumn.styled"

const IconColumn = ({ iconUrl, headline, paragraph }) => (
  <S.Wrapper>
    <S.Icon src={iconUrl} />
    {headline && (
      <S.StyledHeadline sizeType={HeadlineSizes.M} tag="h2">
        {headline}
      </S.StyledHeadline>
    )}

    <S.ParagraphWrapper>{paragraph}</S.ParagraphWrapper>
  </S.Wrapper>
)

IconColumn.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  paragraph: PropTypes.node.isRequired,
}

export default IconColumn
