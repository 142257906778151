import React from "react"
import PropTypes from "prop-types"

import { WrapperSizes } from "components"
import S from "./Breadcrambs.styled"

const Breadcrumbs = ({ crumbs }) => (
  <S.Bar>
    <S.StyledWrapper size={WrapperSizes.LARGE} pushWithPadding>
      <S.InnerWrapper>
        {crumbs?.map(({ path, label }) => (
          <S.CrambLink activeClassName="active" key={Math.random()} to={path}>
            {label}
          </S.CrambLink>
        ))}
      </S.InnerWrapper>
    </S.StyledWrapper>
  </S.Bar>
)

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
}

export default Breadcrumbs
