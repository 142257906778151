import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"

import { devices } from "theme/devices"
import { colors } from "theme/colors"

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background: ${colors.neutral60};
  max-height: 25vh;
  overflow-y: scroll;
  border-top: 0.1rem solid ${colors.neutral20};
  transform: translateY(100%);
  animation: ${slideIn} 1s 1s forwards;

  ${devices.tabletL} {
    flex-direction: row;
    max-height: 50vh;
    overflow-y: auto;
  }
`

const Information = styled.p`
  display: block;
  color: ${colors.neutral20};
  line-height: 1.5;
  padding-top: 2rem;
  font-size: 1rem;
  margin: 0;

  ${devices.tabletL} {
    padding: 0;
    font-size: 1.2rem;
  }

  ${devices.desktop} {
    font-size: 1.4rem;
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: ${colors.secondary20};
  font-weight: 500;
  transition: all 0.25s;

  &:hover {
    color: ${colors.secondary40};
  }

  ${devices.tabletL} {
    display: inline-block;
  }
`

const Data = styled.span`
  margin-right: 0.5rem;
`

const Button = styled.button`
  display: inline-block;
  padding: 0 3rem;
  margin: 2rem 0 0;
  border: none;
  background: ${colors.secondary20};
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: 0.15em;
  line-height: 4rem;
  transition: transform 0.25s ease-in-out;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    background: ${colors.secondary40};
  }

  ${devices.tabletL} {
    margin: 0 2rem 0 0;
  }
`

export default { Wrapper, Information, StyledLink, Button, Data }
