import React from "react"
import PropTypes from "prop-types"

import S, { HeadlineSizes } from "./Headline.styled"

const Headline = ({ children, sizeType, tag, className }) => (
  <S.Headline sizeType={sizeType} as={tag} className={className}>
    {children}
  </S.Headline>
)

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  sizeType: PropTypes.oneOf([
    HeadlineSizes.XXL,
    HeadlineSizes.XL,
    HeadlineSizes.L,
    HeadlineSizes.M,
    HeadlineSizes.S,
    HeadlineSizes.XS,
    HeadlineSizes.XXS,
  ]).isRequired,
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
  className: PropTypes.string,
}

Headline.defaultProps = {
  className: "",
}

export default Headline
