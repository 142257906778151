import styled from "styled-components"
import { devices } from "theme/devices"

const PhraseLabel = styled.span`
  display: block;
  padding: 0;
  margin: 0 0 0.6em;
  color: ${({ theme }) => theme.primary40};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};

  ${devices.mobileL} {
    font-size: 1.5rem;
  }

  ${devices.tablet} {
    font-size: 1.6rem;
  }

  ${devices.tabletL} {
    font-size: 1.5rem;
  }

  ${devices.desktopHD} {
    font-size: 1.6rem;
  }
`

export default { PhraseLabel }
