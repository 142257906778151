import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"

import S, { BUTTON_STYLE_TYPE } from "./Button.styled"

const query = graphql`
  {
    allFile(filter: { name: { regex: "/arrow-button/" } }) {
      nodes {
        name
        publicURL
      }
    }
  }
`

const Button = ({ children, type, onClick, styleType, link, className }) => {
  const {
    allFile: { nodes: arrows },
  } = useStaticQuery(query)

  return (
    <S.Button
      className={className}
      onClick={onClick}
      as={link && Link}
      type={type}
      to={link}
    >
      <S.Text styleType={styleType}>{children}</S.Text>
      <S.Icon
        src={
          styleType === BUTTON_STYLE_TYPE.DARK
            ? arrows.filter(arrow => arrow.name === "arrow-button-blue")[0]
                .publicURL
            : arrows.filter(arrow => arrow.name === "arrow-button-white")[0]
                .publicURL
        }
        alt="arrow"
      />
    </S.Button>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  styleType: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  link: PropTypes.string,
}

Button.defaultProps = {
  styleType: BUTTON_STYLE_TYPE.DARK,
  className: "",
  type: "button",
  onClick: null,
  link: "",
}

export default Button
