import React from "react"
import PropTypes from "prop-types"

import S from "./Wrapper.styled"

export const WrapperSizes = {
  SMALL: "884px",
  MEDIUM: "1024px",
  LARGE: "1504px",
}

const Wrapper = ({ children, size, className, pushWithPadding, _ref }) => (
  <S.Wrapper
    ref={_ref}
    maxWidth={size}
    className={className}
    pushWithPadding={pushWithPadding}
  >
    {children}
  </S.Wrapper>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([
    WrapperSizes.SMALL,
    WrapperSizes.MEDIUM,
    WrapperSizes.LARGE,
  ]).isRequired,
  className: PropTypes.string,
  pushWithPadding: PropTypes.bool,
}

Wrapper.defaultProps = {
  className: "",
  pushWithPadding: false,
}

export default Wrapper
