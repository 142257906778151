import React from "react"
import PropTypes from "prop-types"

import { Marker, Paragraph, ParagraphSizes } from "ui"
import S from "./MarkedParagraph.styled"

const MarkedParagraph = ({ paragraphs }) => (
  <S.Wrapper>
    <Marker />
    <Paragraph sizeType={ParagraphSizes.L} as="div">
      {paragraphs}
    </Paragraph>
  </S.Wrapper>
)

MarkedParagraph.propTypes = {
  paragraphs: PropTypes.node.isRequired,
}

export default MarkedParagraph
