import React from "react"
import PropTypes from "prop-types"
import S from "./Burger.styled"

const Burger = ({ handleClick, active }) => (
  <S.Button type="button" onClick={handleClick} active={active} />
)

Burger.propTypes = {
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
}

export default Burger
