import styled, { css } from "styled-components"

export const BUTTON_STYLE_TYPE = {
  LIGHT: "LIGHT",
  DARK: "DARK",
}

const Text = styled.span`
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0.8em 0 0.7em;
  color: ${({ theme }) => theme.neutral60};
  font-weight: 600;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 25%;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.secondary20};
  }

  ${({ styleType }) =>
    styleType === BUTTON_STYLE_TYPE.LIGHT &&
    css`
      color: ${({ theme }) => theme.neutral20};

      &::before {
        background-color: ${({ theme }) => theme.primary40};
      }
    `};
`

const Icon = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: calc(-25% - 12px);
  transform: translate(0, -50%);
  display: block;
  transition: transform 0.25s ease-in-out;
`

const Button = styled.button`
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0.5em 0;
  border: none;
  background: none;
  font-size: 1.6rem;
  line-height: 1;
  transition: transform 0.25s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    ${Icon} {
      transform: translate(12px, -50%);
    }
  }
`

export default { Button, Text, Icon }
