export const devices = {
  mobile: `@media (min-width: 480px)`,
  mobileL: `@media (min-width: 640px)`,
  tablet: `@media (min-width: 768px)`,
  tabletL: `@media (min-width: 1024px)`,
  desktop: `@media (min-width: 1366px)`,
  desktopHD: `@media (min-width: 1600px)`,
}

export default devices
