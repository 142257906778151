import styled, { keyframes } from "styled-components"
import { colors } from "theme/colors"

const cycle = keyframes`
   0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
   }

   50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
   }

   100% {
      transform: rotate(1800deg);
   }
`

const Wrapper = styled.div`
  position: arelative;
  display: block;
  margin: 0 auto;
  width: 6rem;
  height: 6rem;
  padding: 10rem 0;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0.8rem;
    box-sizing: border-box;
    border: 3.2rem solid ${colors.primary20};
    border-color: ${colors.primary20} transparent ${colors.primary20}
      transparent;
    animation: ${cycle} 1.2s infinite;
  }
`

export default { Wrapper }
