import React from "react"
import PropTypes from "prop-types"

import S from "./Input.styled"

const Input = ({ id, type, name, value, onChange, onBlur, placeholder }) => (
  <S.Input
    type={type !== "textarea" ? type : ""}
    name={name}
    id={id}
    as={type === "textarea" && "textarea"}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    placeholder={placeholder}
    typeTextarea={type === "textarea"}
  />
)

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
}

export default Input
