import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { devices } from "theme/devices"
import { Paragraph } from "ui"

const Box = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  border-radius: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.primary20};
  margin: 0 0 2rem 0;

  ${devices.tablet} {
    margin-bottom: 4rem;
  }

  &:last-of-type {
    margin: 0;
  }

  ${devices.tabletL} {
    width: calc(50% - 1rem);
    margin: 0 0 2rem 0;

    &:nth-child(odd) {
      margin-right: 1rem;
    }

    &:nth-child(even) {
      margin-left: 1rem;
    }

    &:last-of-type {
      margin: 0 0 2rem 0;
    }
  }

  ${devices.desktopHD} {
    width: calc(50% - 1.5rem);
    margin: 0 0 3rem 0;

    &:nth-child(odd) {
      margin-right: 1.5rem;
    }

    &:nth-child(even) {
      margin-left: 1.5rem;
    }

    &:last-of-type {
      margin: 0 0 3rem 0;
    }
  }
`

const NewsData = styled.div`
  padding: 3rem;

  ${devices.mobileL} {
    padding: 4rem;
  }

  ${devices.tabletL} {
    padding: 3rem;
  }

  ${devices.desktopHD} {
    padding: 4rem;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 30rem;
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledParagraph = styled(Paragraph)`
  margin: 0;

  ${devices.desktopHD} {
    margin: 2rem 0 0;

    &:last-of-type {
      margin: 2rem 0 0;
    }
  }

  > p {
    line-height: 1.75;
    color: ${({ theme }) => theme.neutral60};
  }
`

export default { Box, StyledParagraph, ImageWrapper, NewsData, StyledImage }
