import styled from "styled-components"
import { devices } from "theme/devices"
import { Link } from "gatsby"

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const LanguageItem = styled(Link)`
  position: relative;
  display: block;
  padding: 0;
  margin: 0 0 0 4rem;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;

  &.active {
    font-weight: 700;
  }

  &:first-of-type {
    margin: 0;
  }

  &:not(:first-of-type)::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%);
    display: block;
    height: 1.9rem;
    width: 0.2rem;
    background-color: ${({ theme }) => theme.neutral60};
  }

  ${devices.mobileL} {
    font-size: 1.6rem;
  }

  ${devices.tablet} {
    font-size: 1.8rem;
  }

  ${devices.tabletL} {
    font-size: 1.3rem;
  }

  ${devices.desktop} {
    font-size: 1.4rem;
  }
`

const WrapperMobile = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 4rem 0 0;

  ${LanguageItem} {
    &:first-of-type {
      margin: 0;
    }
  }

  ${devices.mobileL} {
    margin-top: 6rem;
  }

  ${devices.tablet} {
    margin-top: 8rem;
  }

  ${devices.tabletL} {
    display: none;
  }
`

export default { Wrapper, WrapperMobile, LanguageItem }
