import React from "react"
import PropTypes from "prop-types"

import { HeadlineSizes } from "ui"
import { WrapperSizes } from "components"
import GalleryItem from "./GalleryItem"
import S from "./GallerySection.styled"

const GallerySection = ({ headline, galeries, _ref }) => (
  <S.StyledWrapper size={WrapperSizes.SMALL} _ref={_ref}>
    <S.StyledHeadline sizeType={HeadlineSizes.L} tag="h2">
      {headline}
    </S.StyledHeadline>
    <S.Gallery>
      {galeries
        ?.filter(item => item !== undefined || item !== null)
        ?.map(image => (
          <GalleryItem imageUrl={image} key={Math.random()} />
        ))}
    </S.Gallery>
  </S.StyledWrapper>
)

GallerySection.propTypes = {
  headline: PropTypes.string.isRequired,
  galeries: PropTypes.arrayOf(
    PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
    })
  ).isRequired,
}

export default GallerySection
