import React, { useState, useContext, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Wrapper, WrapperSizes } from "components"
import parse from "html-react-parser"

import PeakWindLogo from "ui/PeakWindLogo"
import { State } from "layouts"
import S from "./Footer.styled"

const query = graphql`
  {
    allFile(filter: {name: { regex: "/cip|arjun/"	}}) {
      nodes {
        publicURL
      }
    }
    allWpPage(filter: { footer: { footerLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          footer {
            footerLang
            footerColumnTitle1
            footerColumnTitle1Asset
            footerColumnTitle2
            footerColumnTitle3
            footerColumnTitle4
            footerAddress
          }
        }
      }
    }
  }
`

const Footer = () => {
  const {
    allFile: { nodes },
    allWpPage: { edges },
  } = useStaticQuery(query)

  const { state } = useContext(State)

  const [data, setData] = useState(null)

  useEffect(() => {
    if (!state?.selectedLang) return

    const {
      node: { footer },
    } = edges.find(
      ({ node }) => node?.footer?.footerLang === state.selectedLang
    )
    setData(footer)
  }, [state])

  if (!data) return null

  return (
    <S.Footer>
      <Wrapper size={WrapperSizes.LARGE} pushWithPadding>
        <S.InnerWrapper>
          <S.FooterColumn>
            <S.FooterHeadline>{data?.footerColumnTitle1}</S.FooterHeadline>
            {nodes.map(image => (
              <S.PartnerLogo src={image.publicURL} key={image.publicURL} />
            ))}
            <S.InnerSection>
              <S.FooterHeadline>
                {data?.footerColumnTitle1Asset}
              </S.FooterHeadline>
              <PeakWindLogo />
            </S.InnerSection>
          </S.FooterColumn>
          <S.FooterColumn>
            <S.FooterHeadline>{data?.footerColumnTitle2}</S.FooterHeadline>
            <S.NavigationList>
              <S.NavigationItem>
                <S.NavigationLink
                  to={state.selectedLang === "ES" ? "/" : "/en"}
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Principal" : "Home"}
                </S.NavigationLink>
              </S.NavigationItem>
              <S.NavigationItem>
                <S.NavigationLink
                  to={
                    state.selectedLang === "ES" ? "/acerca-de" : "/en/about-us"
                  }
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Acerca de" : "About us"}
                </S.NavigationLink>
              </S.NavigationItem>
              <S.NavigationItem>
                <S.NavigationLink
                  to={state.selectedLang === "ES" ? "/proyecto" : "/en/project"}
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Proyecto" : "The project"}
                </S.NavigationLink>
              </S.NavigationItem>
              <S.NavigationItem>
                <S.NavigationLink
                  to={
                    state.selectedLang === "ES" ? "/beneficios" : "/en/benefits"
                  }
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Beneficios" : "Benefits"}
                </S.NavigationLink>
              </S.NavigationItem>
              <S.NavigationItem>
                <S.NavigationLink
                  to={state.selectedLang === "ES" ? "/noticias" : "/en/news"}
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Noticias" : "News"}
                </S.NavigationLink>
              </S.NavigationItem>
              <S.NavigationItem>
                <S.NavigationLink
                  to={state.selectedLang === "ES" ? "/contacto" : "/en/contact"}
                  activeClassName="active"
                >
                  {state.selectedLang === "ES" ? "Contacto" : "Contact"}
                </S.NavigationLink>
              </S.NavigationItem>
            </S.NavigationList>
          </S.FooterColumn>
          <S.FooterColumn>
            <S.FooterHeadline>{data?.footerColumnTitle3}</S.FooterHeadline>
            <S.ContactDetails>
              {parse(data?.footerAddress, { trim: true })}
            </S.ContactDetails>
          </S.FooterColumn>
          <S.FooterColumn>
            <S.FooterHeadline>{data?.footerColumnTitle4}</S.FooterHeadline>
            <S.NavigationList>
              <S.NavigationItem>
                <S.NavigationLink
                  to={
                    state.selectedLang === "ES"
                      ? "/politica-de-privacidad"
                      : "/en/privacy-policy"
                  }
                  activeClassName="active"
                >
                  {state.selectedLang === "ES"
                    ? "Política de privacidad"
                    : "Privacy Policy"}
                </S.NavigationLink>
              </S.NavigationItem>
            </S.NavigationList>
          </S.FooterColumn>
        </S.InnerWrapper>
      </Wrapper>
    </S.Footer>
  )
}

export default Footer
