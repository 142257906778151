import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { devices } from "theme/devices"

const Column = styled.div`
  margin: 0;

  ${devices.tabletL} {
    width: 50%;
  }
`

const AdditionalSection = styled.div`
  margin-top: 1rem;

  ${devices.tablet} {
    margin-top: 2rem;
  }

  ${devices.tablet} {
    margin-left: 7.4rem;
  }
`

const ImageColumn = styled.div`
  margin: 0 0 2.8rem 0;

  ${devices.mobileL} {
    margin-bottom: 4rem;
  }

  ${devices.tabletL} {
    width: 50%;
  }
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column-reverse;
  margin: 0;

  ${devices.tabletL} {
    display: flex;
    margin: 9rem 0;
    flex-direction: row;

    ${Column} {
      padding: 0 4rem 0 0;
    }

    ${ImageColumn} {
      padding: 0 0 0 4rem;
    }

    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: row-reverse;

        ${Column} {
          padding: 0 0 0 4rem;
        }

        ${ImageColumn} {
          padding: 0 4rem 0 0;
        }
      `}
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  border-radius: 0 5rem 0 0;

  ${({ double }) =>
    double &&
    css`
      &:nth-child(1) {
        margin: 0 2rem 0 0;
      }

      &:nth-child(2) {
        margin: -4rem 0 0 2rem;
      }

      ${devices.tabletL} {
        &:nth-child(1),
        &:nth-child(2) {
          margin: ${({ margin }) => margin || "0"};
        }
      }
    `}

  ${devices.tabletL} {
    width: ${({ width }) => width || "100%"};
    margin: ${({ margin }) => margin || "0"};
    border-radius: 0 0 0 13rem;
  }
`

const StyledImage = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default {
  Wrapper,
  Column,
  ImageWrapper,
  StyledImage,
  ImageColumn,
  AdditionalSection,
}
